<template>
  <div class="forgot-password">
    <b-modal
      @change="change"
      :visible="visible"
      hide-footer
      hide-header
      centered
    >
      <h4 class="text-center px-4 pt-2">
        Digite seu e-mail de cadastro
      </h4>

      <div class="text-center px-4 pt-2 pb-4">
        Você receberá, em seu e-mail, o link para redefenir a sua senha
      </div>

      <form @submit.prevent="submit">
        <form-group
          v-model="email"
          id="email"
          type="email"
          placeholder="Digite a seu e-mail"
          :errors="errors.email"
        />

        <div class="row">
          <div class="col pr-5">
            <button
              class="btn btn-block btn-outline-secondary"
              type="button"
              @click="close"
            >
              Fechar
            </button>
          </div>
          <div class="col pl-5">
            <button
              class="btn btn-block btn-success"
              type="submit"
              :disabled="loading"
            >
              <loading :show="loading">Enviar</loading>
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      defaul: false
    }
  },
  data() {
    return {
      loading: false,
      email: "",
      errors: {
        email: null
      }
    };
  },
  model: {
    prop: "visible",
    event: "change"
  },
  methods: {
    close() {
      this.$emit("change", false);
    },
    change(val) {
      this.$emit("change", val);
    },
    submit() {
      this.loading = true;

      this.$store
        .dispatch("user/forgot", { email: this.email })
        .then(() => {
          this.loading = false;
          this.$message.success("Enviado com sucesso, confira o seu email");
          this.close();
        })
        .catch(error => {
          if (error.response.data.message) {
            this.$message.error(error.response.data.message[0]);
          }
          this.errors = error.response.data;
          this.loading = false;
        });
    }
  }
};
</script>
