<template>
  <div class="view-login auth">
    <form @submit.prevent="submit">
      <div class="body text-dark">
        <img
          v-if="$setup.setup.brand"
          class="brand"
          :src="$setup.setup.brand"
        />

        <h5 class="mt-4">Bem-vindo(a) de volta!</h5>
        <div class="small mb-5">Entre com sua conta Blitzpay.</div>

        <form-group
          v-model="email"
          id="email"
          type="email"
          label="E-mail"
          placeholder="Digite o email cadastrado"
          :errors="errors.email"
        />

        <form-group
          v-model="password"
          id="password"
          type="password"
          label="Senha"
          placeholder="Digite a sua senha"
          :errors="errors.password"
        />

        <p class="mb-4 text-right">
          <a
            href="javascript:void"
            @click="forgotDialog = true"
            class="text-primary btn-forgot"
          >
            Esqueci minha senha
          </a>
        </p>

        <div class="text-center">
          <button
            class="btn btn-primary px-5"
            type="submit"
            :disabled="loading"
          >
            <loading :show="loading">Entrar</loading>
          </button>
        </div>

        <div class="d-flex justify-content-center align-items-end pt-5">
          <div class="pr-2">distribuído por</div>
          <img
            class="blitz-logo img-fluid"
            src="../assets/images/logo-dark.svg"
          />
        </div>
      </div>
    </form>

    <aside
      class="text-light"
      :style="
        $setup.setup.background
          ? `background-image: url(${$setup.setup.background})`
          : ''
      "
    />

    <forgot-password v-model="forgotDialog" />
  </div>
</template>

<script>
import ForgotPassword from "@/components/login/ForgotPassword";

export default {
  name: "login",
  components: {
    ForgotPassword
  },
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: null,
        password: null,
        non_field_errors: null
      },
      loading: false,
      forgotDialog: false
    };
  },
  methods: {
    submit() {
      this.$message.hide();

      this.loading = true;

      this.$store
        .dispatch("user/login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.replace({ name: "home" });
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
          if (this.errors.message) {
            this.$message.error(this.errors.message[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;
  display: flex;
  min-height: 100vh;
  background: white;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  form {
    padding: 8px 0;
  }

  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }

  .logo {
    @media screen and (max-width: 991px) {
      max-width: 48px;
      margin-top: 16px;
    }
    @media screen and (min-width: 992px) {
      max-width: 90px;
      position: absolute;
      top: 32px;
      left: 48px;
    }
  }

  .btn-forgot {
    min-height: 0;
    padding: 0;
    font-weight: bold;
    font-size: 12px;
  }

  a.btn {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .body {
    width: 500px;
    padding: 48px 24px;
  }

  aside {
    position: relative;
    width: 60%;
    min-height: 400px;
    background-image: url(../assets/images/sign-img.svg);
    background-color: #3c3338;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    form,
    aside {
      width: 100%;
    }
  }

  .blitz-logo {
    width: 122px;
  }

  .brand {
    max-width: 200px;
    max-height: 100px;
  }
}
</style>
